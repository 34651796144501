import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
    return (

        <div className="container">
            <main role="main">
                <div className="jumbotron">
                    <div className="col-sm-10 mx-auto text-center pt-5 pb-5">
                        <h1 className="pt-5 pb-5">NADA Definitions & Guides</h1>
                        <Link to="/calculations">
                            <p className="pb-5">
                                <button className="btn btn-primary btn-lg btn-block">View Guides and Compare Your Data &raquo;</button>
                            </p>
                        </Link>
                            <p className="def">New this year to NADA Show is the NADA Guide App, which allows you to enter specific financial 
                        statement data and see how your dealership performance compares. The Guide App analyzes key performance metrics such 
                        as Asset Utilization, Return on Sales, New & Used Salespeople Productivity and Technician Proficiency. ATD Slide Guide App coming soon.
                            </p>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <a href="./NADASlideGuide.pdf" download>

                                    <button type="button" className="btn pdf-button btn-md test">NADA Definitions and Guide PDF <i className="fa fa-download"></i>
                                    </button>
                                </a>
                            </div>
                            <div className="col-md-6 col-sm-12">

                                <a href="./ATDSlideGuide.pdf" download>
                                    <button type="button" className="btn pdf-button btn-md right">ATD Definitions and Guide PDF <i className="fa fa-download"></i>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Home;