import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function NewInventoryDaysSupply() {
    const [newInventory, setNewInventory] = useState(0);
    const [newRetail, setNewRetail] = useState(0);
    const [newFleet, setNewFleet] = useState(0);
    const [retailProfit, setRetailProfit] = useState(0);
    const [fleetProfit, setFleetProfit] = useState(0);
    const [months, setMonths] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();


    useEffect(() => {
        if (parseInt(months) == ' ') {
            setTotal
                ((parseInt(newInventory)) / (((parseInt(newRetail) + parseInt(newFleet)) - (parseInt(retailProfit) + parseInt(fleetProfit)))) * 30);
        }
        else {
            setTotal
                ((parseInt(newInventory)) / (((parseInt(newRetail) + parseInt(newFleet)) - (parseInt(retailProfit) + parseInt(fleetProfit))) / parseInt(months)) * 30);
        }
        

    }, [newInventory, newRetail, newFleet, retailProfit, fleetProfit, months])

    function clickResult() {
        history.push(
            {
                pathname: `/newinventorydayssupplyresult?total=${parseFloat(total).toFixed(1)}`
            })
    }

    const validationSchema = Yup.object({
        newInventory: numberValidation,
        newRetail: numberValidation,
        newFleet: numberValidation,
        retailProfit: numberValidation,
        fleetProfit: numberValidation,
        months: monthValidation
    });

    const def = <span>New Inventory $ ÷ ((((New Vehicle Retail Sales + New Fleet Sales) - (New Vehicle Retail Gross Profit + New Fleet Gross Profit)) ÷ # of Months) x 30)</span>;

    return (
        <Formik
            initialValues={{ newInventory: "", newRetail: "", newFleet: "", retailProfit: "", fleetProfit: "", months: ""}}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">New Inventory Days Supply</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newInventory">
                                            <Form.Label>New Vehicle Inventory</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="newInventory" value={values.newInventory} isInvalid={!!errors.newInventory}
                                                onChange={e => { handleChange(e); setNewInventory(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.newInventory}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newRetail">
                                            <Form.Label>New Vehicle Retail Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="newRetail" value={values.newRetail} isInvalid={!!errors.newRetail}
                                                    onChange={e => { handleChange(e); setNewRetail(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.newRetail}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newFleet">
                                            <Form.Label>New Fleet Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="newFleet" value={values.newFleet} isInvalid={!!errors.newFleet}
                                                onChange={e => { handleChange(e); setNewFleet(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.newFleet}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="retailProfit">
                                            <Form.Label>New Vehicle Retail Gross Profit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="retailProfit" value={values.retailProfit} isInvalid={!!errors.retailProfit}
                                                onChange={e => { handleChange(e); setRetailProfit(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.retailProfit}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="fleetProfit">
                                            <Form.Label>New Fleet Gross Profit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="fleetProfit" value={values.fleetProfit} isInvalid={!!errors.fleetProfit}
                                                onChange={e => { handleChange(e); setFleetProfit(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.fleetProfit}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label>Number of Months</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" value={values.months} isInvalid={!!errors.months}
                                                onChange={e => { handleChange(e); setMonths(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function NewInventoryDaysSupplyResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "New Inventory Days Supply";
    const total = urlParams.get('total') + " Days";
    const ratio = <p>45 Days<br /><h2>(8 turns per year)</h2></p>;

    //const cond = parseFloat(total) >= 15 && parseFloat(total) <= 60;
    const cond = parseFloat(total) <= 45.00;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}

export {
    NewInventoryDaysSupply,
    NewInventoryDaysSupplyResult
}