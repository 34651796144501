import React from 'react';
import BackButton from '../_Shared/BackButton';

function FillRateFirstTime() {
    return (
        <div className="container">
            <div className="jumbotron">
                <div className="col-sm-10 mx-auto">
                    <BackButton /><h2 className="text-center">Fill Rate First Time(off the shelf)</h2>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card text-white rounded formula-definition">
                                <div className="card-header text-center" id="formula">NADA Guide : 90% Minimum Ratio</div>
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export {
    FillRateFirstTime
}