import React from 'react';
import BackButton from '../_Shared/BackButton';




function NetWorth() {
    return (
        <div className="container">
            <div className="jumbotron">
                <div className="col-sm-10 mx-auto">
                    <BackButton /><h2 className="text-center">Net Worth</h2>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="col-md-12 col-sm-12">
                                <div className="card text-white rounded formula-definition">
                                    <div className="card-header text-center" id="formula">Formula Definition: The sum of stock value, additional paid in capital, 
                                    retained earnings and year-to-date dealership net profit minus dividends and/or drawings.  
                                        Note: LIFO dealers add 60% of LIFO reserve.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}




export {
    NetWorth
}