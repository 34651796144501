import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function WarrantyReceivables() {
    const [warranty, setWarranty] = useState(0);
    const [service, setService] = useState(0);
    const [parts, setParts] = useState(0);
    const [bodyShopSales, setBodyShopSales] = useState(0);
    const [months, setMonths] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();


    useEffect(() => {
        setTotal
            (
                (parseInt(warranty) / ((parseInt(service) + parseInt(parts) + parseInt(bodyShopSales)) / parseInt(months)))
            );

    }, [warranty, service, parts, bodyShopSales, months])

    function clickResult() {
        history.push(
            {
                pathname: `/warrantyreceivablesresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        warranty: numberValidation,
        service: numberValidation,
        parts: numberValidation,
        bodyShopSales: numberValidation,
        months: monthValidation
    });

    const def = <span>Warranty Receivables &#247; ((Service + Parts + Body Shop Sales) &#247; # of Months)  </span>;

    return (
        <Formik
            initialValues={{ warranty: "", service: "", parts: "", bodyShopSales: "", months: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Warranty Receivables % Sales</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="warranty">
                                            <Form.Label>Warranty Receivables</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                             <Form.Control size="lg" type="number" name="warranty" value={values.warranty} isInvalid={!!errors.warranty}
                                                 onChange={e => { handleChange(e); setWarranty(e.target.value) }} />
                                             <Form.Control.Feedback type="invalid">{errors.warranty}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="service">
                                            <Form.Label>Service</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="service" value={values.service} isInvalid={!!errors.service}
                                                onChange={e => { handleChange(e); setService(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.service}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="parts">
                                            <Form.Label>Parts</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="parts" value={values.parts} isInvalid={!!errors.parts}
                                                onChange={e => { handleChange(e); setParts(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.parts}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="bodyShopSales">
                                            <Form.Label>Body Shop Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="bodyShopSales" value={values.bodyShopSales} isInvalid={!!errors.bodyShopSales}
                                                onChange={e => { handleChange(e); setBodyShopSales(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.bodyShopSales}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label>Number of Months</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" value={values.months} isInvalid={!!errors.months}
                                                onChange={e => { handleChange(e); setMonths(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function WarrantyReceivablesResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Warranty Receivables % Sales";
    const total = urlParams.get('total') + '%';
    const ratio = "25%";

    const cond = parseFloat(total) <= 25;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    WarrantyReceivables,
    WarrantyReceivablesResult
}