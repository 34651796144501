import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function UsedVehiclesDaysSupplyInDollars() {
    const [usedInventory, setUsedInventory] = useState(0);
    const [grossProfit, setGrossProfit] = useState(0);
    const [usedSale, setUsedSale] = useState(0);
    const [months, setMonths] = useState(0);
    const [numberOfMonths, setNumberOfMonths] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();


    useEffect(() => {
        if (parseInt(numberOfMonths) == ' ') {
            setTotal(
                ((parseInt(usedInventory) / (parseInt(usedSale) - parseInt(grossProfit))) * 30)
            );
        }
        else {
            setTotal(
                ((parseInt(usedInventory) / ((parseInt(usedSale) - parseInt(grossProfit)) / parseInt(numberOfMonths))) * 30)
            );
        }
    }, [usedInventory, grossProfit, usedSale, numberOfMonths])

    function clickResult() {
        history.push(
            {
                pathname: `/usedvehiclesdaysdupplyindollarsresult?total=${parseFloat(total).toFixed(1)}`
            })
    }

    const validationSchema = Yup.object({
        usedInventory: numberValidation,
        grossProfit: numberValidation,
        usedSale: numberValidation,
        //months: numberValidation, 
        numberOfMonths: monthValidation
    });

    const def = <span>Used Vehicle Inventory Dollar Amount ÷ Used Vehicle Cost of Sale (Average Month) x 30 Days</span>;

    return (
        <Formik
            initialValues={{ usedInventory: "", grossProfit: "", usedSale:"", numberOfMonths: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Used Vehicles Days Supply in Dollars</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedInventory">
                                            <Form.Label>Used Vehicle Inventory Dollar Amount </Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="usedInventory" value={values.usedInventory} isInvalid={!!errors.usedInventory}
                                                onChange={e => { handleChange(e); setUsedInventory(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.usedInventory}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="grossProfit">
                                            <Form.Label>Used Vehicle Gross Profit without F&I</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="grossProfit" isInvalid={!!errors.grossProfit}
                                                onChange={e => { handleChange(e); setGrossProfit(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.grossProfit}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedSale">
                                            <Form.Label>Used Vehicle Sales $ without F&I</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="usedSale" isInvalid={!!errors.usedSale}
                                                    onChange={e => { handleChange(e); setUsedSale(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.usedSale}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="numberOfMonths">
                                            <Form.Label>Number of Months</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="numberOfMonths" isInvalid={!!errors.numberOfMonths}
                                                    onChange={e => { handleChange(e); setNumberOfMonths(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.numberOfMonths}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        {/*
                                            <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label>Number of Working Days per Months</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="months" isInvalid={!!errors.months}
                                                onChange={e => { handleChange(e); setMonths(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                            </Form.Group>}
                                        */}

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function UsedVehiclesDaysSupplyInDollarsResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Used Vehicles Days Supply in Dollars";
    const total = urlParams.get('total') + " Days";
    const ratio = <p>30 Days<br /><h2>12 turns per year</h2></p>;

    const cond = parseFloat(total) <= 30.00; 

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    UsedVehiclesDaysSupplyInDollars,
    UsedVehiclesDaysSupplyInDollarsResult
}