import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function DebtToEquity() {
    const [liabilities, setLiabilities] = useState(0);
    const [networth, setNetWorth] = useState(0);
    const [lifo, setLifo] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal(
            (parseInt(liabilities) / (parseInt(networth) + (0.6 * parseInt(lifo))))
        );
    }, [liabilities, networth, lifo])

    function clickResult() {
        history.push(
            {
                pathname: `/debttoequityresult?total=${total.toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        liabilities: numberValidation,
        networth: numberValidation,
        lifo: numberValidation
    });

    const def = <span>Total liabilities ÷ equity (net worth) + 60% of LIFO balances. The higher the number the lower the corporation’s borrowing power.</span>;

    return (
        <Formik
            initialValues={{ liabilities: "", networth: "", lifo: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Debt To Equity</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="liabilities">
                                            <Form.Label>Total Liabilities</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="liabilities" isInvalid={!!errors.liabilities}
                                                value={values.liabilities} onChange={e => { handleChange(e); setLiabilities(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.liabilities}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="networth">
                                            <Form.Label>Net Worth</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="networth" isInvalid={!!errors.networth}
                                                value={values.networth} onChange={e => { handleChange(e); setNetWorth(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.networth}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="lifo">
                                            <Form.Label>LIFO Balances</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="lifo" isInvalid={!!errors.lifo}
                                                value={values.lifo} onChange={e => { handleChange(e); setLifo(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.lifo}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function DebtToEquityResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Debt To Equity";
    const total = urlParams.get('total') + " to 1";
    const ratio = "3 to 1 or less";
    const cond = parseFloat(total) <= 3;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    DebtToEquity,
    DebtToEquityResult
}