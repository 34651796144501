import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function UsedToNewRetailRatio() {
    const [usedretial, setUsedRetail] = useState(0);
    const [newretail, setNewRetail] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();


    useEffect(() => {
        setTotal(parseInt(usedretial) / parseInt(newretail));
    }, [usedretial, newretail ])

    function clickResult() {
        history.push(
            {
                pathname: `/usedtonewretailratioresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        usedretial: numberValidation,
        newretail: numberValidation
    });

    const def = <span>Used Retail Units Sold &#247; New Retail Units Sold</span>;

    return (
        <Formik
            initialValues={{usedretial: "", newretail: ""}}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Used to New Retail Ratio</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedretial">
                                            <Form.Label>Used Retail Units Sold </Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="usedretial" value={values.usedretial} isInvalid={!!errors.usedretial}
                                                onChange={e => { handleChange(e); setUsedRetail(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.usedretial}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newretail">
                                            <Form.Label>New Retail Units Sold </Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="newretail" value={values.newretail} isInvalid={!!errors.newretail}
                                                onChange={e => { handleChange(e); setNewRetail(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.newretail}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function UsedToNewRetailRatioResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Used to New Retail Ratio";
    const total = urlParams.get('total')+ " - 1";
    const ratio = "1.25 - 1";

    //const cond = parseFloat(total) >= 1 && parseFloat(total) <= 1.25;
    const cond = parseFloat(total) >= 1.25;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}

export {
    UsedToNewRetailRatio,
    UsedToNewRetailRatioResult
}