import React from 'react';
import BackButton from '../_Shared/BackButton';




function InventoryTrustPosition() {
    return (
        <div className="container">
            <div className="jumbotron">
                <div className="col-sm-10 mx-auto">
                    <BackButton /><h2 className="text-center">Inventory Trust Position</h2>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card text-white rounded formula-definition">
                                <div className="card-header text-center" id="formula">Formula Definition: Inventory of new vehicles + demos + holdback receivable 
                                (if applicable) minus notes payable on new and demo units.
                                    <br />
                                    NADA Guide: Positive or zero inventory trust position balance. 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}




export {
    InventoryTrustPosition
}