import React from 'react';
import BackButton from '../_Shared/BackButton';
 
function PartsActiveNormal() {
return (
    <div className="container">
        <div className="jumbotron">
            <div className="col-sm-10 mx-auto">
                <BackButton /><h2 className="text-center">Active/Normal Status</h2>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                            <div className="card text-white rounded formula-definition">
                                <div className="card-header text-center" id="formula">NADA Guide : 75% Minimum Ratio</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}


export {
    PartsActiveNormal
}