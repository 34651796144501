import React from 'react';
import BackButton from '../_Shared/BackButton';




function VariableExpense() {
    return (
        <div className="container">
            <div className="jumbotron">
                <div className="col-sm-10 mx-auto">
                    <BackButton /><h2 className="text-center">Variable Expense</h2>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="col-md-12 col-sm-12">
                                <div className="card text-white rounded formula-definition">
                                    <div className="card-header text-center" id="formula">Formula Definition: Expenses that change with unit sales volume:
                                    salesperson commissions, delivery expense and policy expense.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}




export {
    VariableExpense
}