import React from 'react';
import BackButton from '../_Shared/BackButton';




function UnitsInOperation() {
    return (
        <div className="container">
            <div className="jumbotron">
                <div className="col-sm-10 mx-auto">
                    <BackButton /><h2 className="text-center">Units In Operation</h2>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="col-md-12 col-sm-12">
                                <div className="card text-white rounded formula-definition">
                                    <div className="card-header text-center" id="formula">Formula Definition: Number of franchise brand vehicles in operation 
                                    in a given market area that are 5-, 7-, of 10-year* old model or older.
                                        <br />
                                        NADA Guide : (*Manufacturer specifies number of years)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}




export {
    UnitsInOperation
}