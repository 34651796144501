import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function NewVehicleDepartmentBreakevenPoint() {
    const [expense, setExpense] = useState(0);
    const [gross, setGross] = useState(0);
    const [retail, setRetail] = useState(0);
    const [months, setMonths] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        if (parseInt(months) == ' ') {
            setTotal(
                (parseInt(expense) / (parseInt(gross) / parseInt(retail)))
            );
        }
        else {
            setTotal(
                ((parseInt(expense) / parseInt(months)) / (parseInt(gross) / parseInt(retail)))
            );
        }
    }, [expense, gross, retail, months])

    function clickResult() {
        history.push(
            {
                pathname: `/newvehicledepartmentbreakevenpointresult?total=${parseInt(total)}`,
                state: { retail : (parseInt(retail) / parseInt(months))}
            })
    }

    const validationSchema = Yup.object({
        expense: numberValidation,
        gross: numberValidation,
        retail: numberValidation,
        months: monthValidation
    });

    const def = <span>Total New-Vehicle Department Expenses ÷ Average Gross Profit per New Vehicle Retailed (PNVR)</span>;

    return (
        <Formik
            initialValues={{ expense: "", gross: "", retail:"", months: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">New Vehicle Department Breakeven Point (in units)</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="expense">
                                            <Form.Label>Total New-Vehicle Department Expenses</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="expense" isInvalid={!!errors.expense}
                                                value={values.expense} onChange={e => { handleChange(e); setExpense(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.expense}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="gross">
                                            <Form.Label>Total New Vehicle Department Gross Profit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="gross" isInvalid={!!errors.gross}
                                                value={values.gross} onChange={e => { handleChange(e); setGross(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.gross}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="retail">
                                            <Form.Label>New Retail Units Sold</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="retail" isInvalid={!!errors.retail}
                                                value={values.retail} onChange={e => { handleChange(e); setRetail(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.retail}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label># Months of Gross $ and Units Above</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="months" isInvalid={!!errors.months}
                                                value={values.months} onChange={e => { handleChange(e); setMonths(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function NewVehicleDepartmentBreakevenPointResult() {
    const history = useHistory();
    const newUnits = history.location.state.retail;
    const urlParams = new URLSearchParams(window.location.search);
    const title = "New Vehicle Department Breakeven Point (in units)";
    const total = urlParams.get('total');
    const ratio = "Less Than New Retail Units Sold Per Month";

    const cond = parseFloat(total) <= newUnits;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    NewVehicleDepartmentBreakevenPoint,
   NewVehicleDepartmentBreakevenPointResult
}