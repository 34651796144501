import React from 'react';
import '../../App.css';


function VersionFooter() {
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className="version-number">
          Version: 1.0.0
          </div>
        </div>
      </div>
    </div>
  );
}

export default VersionFooter;
