import React from 'react';
import BackButton from '../_Shared/BackButton';

function InventoryProfile() {
    return (
        <div className="container">
            <div className="jumbotron">
                <div className="col-sm-10 mx-auto">
                    <BackButton /><h2 className="text-center">Inventory Profile - Sales Movement Range</h2>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card text-white rounded formula-definition">
                                <div className="card-header text-center" id="formula">NADA Guide <br /> 0-6 Months : 85% of Inventory <br /> 6-12 Months : 10-15% of Inventory <br /> Over 12 Months : &#60;5% of Inventory</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export {
    InventoryProfile
}