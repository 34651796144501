import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function TechProductivity() {
    const [availableHours, setAvailableHours] = useState(0);
    const [actualHours, setActualHours] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal((actualHours / availableHours) * 100);
    }, [availableHours, actualHours])

    function clickResult() {
        history.push(
            {
                pathname: `/techproductivityresult?total=${total.toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        actualHours: numberValidation,
        availableHours: numberValidation
    });

    const def = <span>Service Actual Hours for all Techs &#247; Available Hours for all Techs</span>;

    return (
        <Formik
            initialValues={{ actualHours: "", availableHours: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Tech Productivity</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" mb="3" controlId="availableHours">
                                            <Form.Label>Total Available Service Hours for all Techs</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="availableHours" value={values.availableHours} isInvalid={!!errors.availableHours}
                                                onChange={e => { handleChange(e); setAvailableHours(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.availableHours}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="actualHours">
                                                <Form.Label>Total Actual Service Hours for all Techs</Form.Label>
                                                <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="actualHours" value={values.actualHours} isInvalid={!!errors.actualHours}
                                                onChange={e => { handleChange(e); setActualHours(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.actualHours}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>                                       

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn btn-primary btn-lg btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function TechProductivityResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Tech Productivity";
    const total = urlParams.get('total') + '%';
    const ratio = "100%";

    const cond = parseFloat(total) >= 100;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}

export {
    TechProductivity,
    TechProductivityResult
}