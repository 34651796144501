import React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import Home from './components/Home';
import { NavMenu } from './components/_Shared/NavMenu';
import Footer from './components/_Shared/VersionFooter';
import Calculations from './components/Calculations';

//Dealership Vitals
import { AssetUtilization, AssetUtilizationResult } from './components/DealershipVitals/AssetUtilization'
import { CashDaysSupply, CashDaysSupplyResult } from './components/DealershipVitals/CashDaysSupply';
import { CashInBank, CashInBankResult } from './components/DealershipVitals/CashInBank';
import { CurrentRatio, CurrentRatioResult } from './components/DealershipVitals/CurrentRatio';
import { DebtToEquity, DebtToEquityResult } from './components/DealershipVitals/DebtToEquity';
import { FrozenCapital } from './components/DealershipVitals/FrozenCapital';
import { InventoryTrustPosition } from './components/DealershipVitals/InventoryTrustPosition';
import { LIFO } from './components/DealershipVitals/LIFO';
import { NetProfitReturnOnSales, NetProfitReturnOnSalesResult } from './components/DealershipVitals/NetProfitReturnOnSales';
import { NetProfitReturnOnAssets, NetProfitReturnOnAssetsResult } from './components/DealershipVitals/NetProfitReturnOnAssets';
import { NetWorth } from './components/DealershipVitals/NetWorth';
import { NewVehicleDepartmentBreakevenPoint, NewVehicleDepartmentBreakevenPointResult } from './components/DealershipVitals/NewVehicleDepartmentBreakevenPoint';
import { PartsObsolescence, PartsObsolescenceResult } from './components/DealershipVitals/PartsObsolescence';
import { ReturnOnEquity, ReturnOnEquityResult } from './components/DealershipVitals/ReturnOnEquity';
import { ServiceDepartmentProficiency, ServiceDepartmentProficiencyResult } from './components/DealershipVitals/ServiceDepartmentProficiency';
import { ServiceSalesPotentialAndRetention } from './components/DealershipVitals/ServiceSalesPotentialAndRetention'; 
import { TotalAbsorption, TotalAbsorptionResult } from './components/DealershipVitals/TotalAbsorption';
import { FixedAbsorption, FixedAbsorptionResult } from './components/DealershipVitals/FixedAbsorption';
import { UnitsInOperation } from './components/DealershipVitals/UnitsInOperation';
import { UsedVehiclesDaysSupplyInDollars, UsedVehiclesDaysSupplyInDollarsResult } from './components/DealershipVitals/UsedVehiclesDaysSupplyInDollars';
import { UsedVehiclesDaysSupplyInUnits, UsedVehiclesDaysSupplyInUnitsResult } from './components/DealershipVitals/UsedVehiclesDaysSupplyInUnits';
import { VariableExpense } from './components/DealershipVitals/VariableExpense';
import { WorkingCapital } from './components/DealershipVitals/WorkingCapital';

//NEW AND USED VEHICLE DEPARTMENT
import { RetailUnitSoldPerSalespeople, RetailUnitSoldPerSalespeopleResult } from './components/NewandUsedVehicleDepartment/RetailUnitSoldPerSalespeople';
import { UsedToNewRetailRatio, UsedToNewRetailRatioResult } from './components/NewandUsedVehicleDepartment/UsedToNewRetailRatio';
import { NewInventoryDaysSupply, NewInventoryDaysSupplyResult } from './components/NewandUsedVehicleDepartment/NewInventoryDaysSupply';
import { UsedInventoryDaysSupply, UsedInventoryDaysSupplyResult } from './components/NewandUsedVehicleDepartment/UsedInventoryDaysSupply';
import { UsedUnitsOver60Days } from './components/NewandUsedVehicleDepartment/UsedUnitsOver60Days';
import { ReconditioningTurnaroundTime } from './components/NewandUsedVehicleDepartment/ReconditioningTurnaroundTime';


//SERVICE
import { TechSupport, TechSupportResult } from './components/Service/TechSupport';
import { TechProficiency, TechProficiencyResult } from './components/Service/TechProficiency';
import { TechProductivity, TechProductivityResult } from './components/Service/TechProductivity';
import { ServiceLabor, ServiceLaborResult } from './components/Service/ServiceLabor';
import { StallUtilization, StallUtilizationResult } from './components/Service/StallUtilization';
import { CustomerLabor, CustomerLaborResult } from './components/Service/CustomerLabor';
import { InternalLabor, InternalLaborResult } from './components/Service/InternalLabor';
import { WarrantyLabor, WarrantyLaborResult } from './components/Service/WarrantyLabor';
import { GrossSales, GrossSalesResult } from './components/Service/GrossSales';
import { Receivables30, Receivables30Result } from './components/Service/Receivables30';
import { Receivables60, Receivables60Result } from './components/Service/Receivables60';

//PARTS
import { DomesticPartsSalesPerPartsEmployee, DomesticPartsSalesPerPartsEmployeeResult } from './components/Parts/DomesticPartsSalesPerPartsEmployee';
import { ImportNonLuxuryPartsSalesPerPartsEmployee, ImportNonLuxuryPartsSalesPerPartsEmployeeResult } from './components/Parts/ImportNonLuxuryPartsSalesPerPartsEmployee';
import { LuxuryPartsSalesPerPartsEmployee, LuxuryPartsSalesPerPartsEmployeeResult } from './components/Parts/LuxuryPartsSalesPerPartsEmployee';
import { DomesticPartsGrossPerPartsEmployee, DomesticPartsGrossPerPartsEmployeeResult } from './components/Parts/DomesticPartsGrossPerPartsEmployee ';
import { ImportNonLuxuryPartsGrossPerPartsEmployee, ImportNonLuxuryPartsGrossPerPartsEmployeeResult } from './components/Parts/ImportNonLuxuryPartsGrossPerPartsEmployee';
import { LuxuryPartsGrossPerPartsEmployee, LuxuryPartsGrossPerPartsEmployeeResult } from './components/Parts/LuxuryPartsGrossPerPartsEmployee';
import { PartsToLaborRatio, PartsToLaborRatioResult } from './components/Parts/PartsToLaborRatio';
import { PartsTurnover, PartsTurnoverResult } from './components/Parts/PartsTurnover';
import { InventoryProfile } from './components/Parts/InventoryProfile';
import { PartsMonthsSupply, PartsMonthsSupplyResult } from './components/Parts/PartsMonthsSupply';
import { FillRateFirstTime } from './components/Parts/FillRateFirstTime';
import { FillRateSameDay } from './components/Parts/FillRateSameDay';
import { PartsActiveNormal } from './components/Parts/PartsActiveNormal';

//PARTS - Gross Profit
import { TotalPartsDepartmentGrossPercentSales, TotalPartsDepartmentGrossPercentSalesResult } from './components/Parts/TotalPartsDepartmentGrossPercentSales';
import { WarrantyReceivables, WarrantyReceivablesResult } from './components/Parts/WarrantyReceivables';

//PARTS - Body Shop
import { TechnicianEfficiency, TechnicianEfficiencyResults } from './components/Parts/TechnicianEfficiency';
//PARTS - Body Gross Profit
import { BodyShopGrossSales, BodyShopGrossSalesResults } from './components/Parts/BodyShopGrossSales';
import { BodyShopLaborGrossSales, BodyShopLaborGrossSalesResults } from './components/Parts/BodyShopLaborGrossSales';
import { PartsBodyShopGrossSales, PartsBodyShopGrossSalesResults } from './components/Parts/PartsBodyShopGrossSales';
import { BodyShopPaintMaterialsSales, BodyShopPaintMaterialsSalesResults } from './components/Parts/BodyShopPaintMaterialsSales';

function App() {
    return (
        < div className="page-container">
            <div className="content-wrap">
                <Router>
                <NavMenu />
                    <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/calculations' component={Calculations} />

                    {/*----DEALERSHIPS VITALS----*/}
                    <Route path='/assetutilization' component={AssetUtilization} />
                    <Route path='/assetutilizationresult?total=:total' component={AssetUtilizationResult} />
                    <Route path='/cashdayssupply' component={CashDaysSupply} />
                    <Route path='/cashdayssupplyresult?total=:total' component={CashDaysSupplyResult} />
                    <Route path='/cashinbank' component={CashInBank} />
                    <Route path='/cashinbankresult?total=:total' component={CashInBankResult} />
                    <Route path='/currentratio' component={CurrentRatio} />
                    <Route path='/currentratioresult?total=:total' component={CurrentRatioResult} />
                    <Route path='/debttoequity' component={DebtToEquity} />
                    <Route path='/debttoequityresult?total=:total' component={DebtToEquityResult} />
                    <Route path='/frozencapital' component={FrozenCapital} />
                    <Route path='/inventorytrustposition' component={InventoryTrustPosition} />
                    <Route path='/lifo' component={LIFO} />
                    <Route path='/netprofitreturnonsales' component={NetProfitReturnOnSales} />
                    <Route path='/netprofitreturnonsalesresult?total=:total' component={NetProfitReturnOnSalesResult} />
                    <Route path='/netprofitreturnonassets' component={NetProfitReturnOnAssets} />
                    <Route path='/netprofitreturnonassetsresult?total=:total' component={NetProfitReturnOnAssetsResult} />
                    <Route path='/networth' component={NetWorth} />
                    <Route path='/newvehicledepartmentbreakevenpoint' component={NewVehicleDepartmentBreakevenPoint} />
                    <Route path='/newvehicledepartmentbreakevenpointresult?total=:total' component={NewVehicleDepartmentBreakevenPointResult} />
                    <Route path='/partsobsolescence' component={PartsObsolescence} />
                    <Route path='/partsobsolescenceresult?total=:total' component={PartsObsolescenceResult} />
                    <Route path='/returnonequity' component={ReturnOnEquity} />
                    <Route path='/returnonequityresult?total=:total' component={ReturnOnEquityResult} />
                    <Route path='/servicedepartmentproficiency' component={ServiceDepartmentProficiency} />
                    <Route path='/servicedepartmentproficiencyresult?total=:total' component={ServiceDepartmentProficiencyResult} />
                    <Route path='/servicesalespotentialandretention' component={ServiceSalesPotentialAndRetention} />
                    <Route path='/totalabsorption' component={TotalAbsorption} />
                    <Route path='/totalabsorptionresult?total=:total' component={TotalAbsorptionResult} />
                    <Route path='/fixedabsorption' component={FixedAbsorption} />
                    <Route path='/fixedabsorptionresult?total=:total' component={FixedAbsorptionResult} />
                    <Route path='/unitsinoperation' component={UnitsInOperation} />
                    <Route path='/usedvehiclesdaysdupplyindollars' component={UsedVehiclesDaysSupplyInDollars} />
                    <Route path='/usedvehiclesdaysdupplyindollarsresult?total=:total' component={UsedVehiclesDaysSupplyInDollarsResult} />
                    <Route path='/usedvehiclesdaysdupplyinunits' component={UsedVehiclesDaysSupplyInUnits} />
                    <Route path='/usedvehiclesdaysdupplyinunitsresult?total=:total' component={UsedVehiclesDaysSupplyInUnitsResult} />
                    <Route path='/variableexpense' component={VariableExpense} />
                    <Route path='/workingcapital' component={WorkingCapital} />

                    {/*----NEW AND USED VEHICLE DEPARTMENT----*/}
                    <Route path='/retailunitssoldpersalespeople' component={RetailUnitSoldPerSalespeople} />
                    <Route path='/retailunitssoldpersalespeopleresult?total=:total' component={RetailUnitSoldPerSalespeopleResult} />
                    <Route path='/usedtonewretailratio' component={UsedToNewRetailRatio} />
                    <Route path='/usedtonewretailratioresult?total=:total' component={UsedToNewRetailRatioResult} />
                    <Route path='/newinventorydayssupply' component={NewInventoryDaysSupply} />
                    <Route path='/newinventorydayssupplyresult?total=:total' component={NewInventoryDaysSupplyResult} />
                    <Route path='/usedinventorydayssupply' component={UsedInventoryDaysSupply} />
                    <Route path='/usedinventorydayssupplyresult?total=:total' component={UsedInventoryDaysSupplyResult} />
                    <Route path='/usedunitsover60days' component={UsedUnitsOver60Days} />
                    <Route path='/reconditioningturnaroundtime' component={ReconditioningTurnaroundTime} />

                    {/*----SERVICE----*/}
                    <Route path='/techsupport' component={TechSupport} />
                    <Route path='/techsupportresult?total=:total' component={TechSupportResult} />
                    <Route path='/techproficiency' component={TechProficiency} />
                    <Route path='/techproficiencyresult?total=:total' component={TechProficiencyResult} />
                    <Route path='/techproductivity' component={TechProductivity} />
                    <Route path='/techproductivityresult?total=:total' component={TechProductivityResult} />
                    <Route path='/servicelabor' component={ServiceLabor} />
                    <Route path='/servicelaborresult?total=:total' component={ServiceLaborResult} />
                    <Route path='/stallutilization' component={StallUtilization} />
                    <Route path='/stallutilizationresult?total=:total' component={StallUtilizationResult} />
                    <Route path='/customerlabor' component={CustomerLabor} />
                    <Route path='/customerlaborresult?total=:total' component={CustomerLaborResult} />
                    <Route path='/internallabor' component={InternalLabor} />
                    <Route path='/internallaborresult?total=:total' component={InternalLaborResult} />
                    <Route path='/warrantylabor' component={WarrantyLabor} />
                    <Route path='/warrantylaborresult?total=:total' component={WarrantyLaborResult} />
                    <Route path='/grosssales' component={GrossSales} />
                    <Route path='/grosssalesresult?total=:total' component={GrossSalesResult} />
                    <Route path='/receivables30' component={Receivables30} />
                    <Route path='/receivables30result?total=:total' component={Receivables30Result} />
                    <Route path='/receivables60' component={Receivables60} />
                    <Route path='/receivables60result?total=:total' component={Receivables60Result} />

                    {/*----PARTS----*/}
                    <Route path='/domesticpartssalesperpartsemployee' component={DomesticPartsSalesPerPartsEmployee} />
                    <Route path='/domesticpartssalesperpartsemployeeresult?total=:total' component={DomesticPartsSalesPerPartsEmployeeResult} />
                    <Route path='/importnonluxurypartssalesperpartsemployee' component={ImportNonLuxuryPartsSalesPerPartsEmployee} />
                    <Route path='/importnonluxurypartssalesperpartsemployeeresult?total=:total' component={ImportNonLuxuryPartsSalesPerPartsEmployeeResult} />
                    <Route path='/luxurypartssalesperpartsemployee' component={LuxuryPartsSalesPerPartsEmployee} />
                    <Route path='/luxurypartssalesperpartsemployeeresult?total=:total' component={LuxuryPartsSalesPerPartsEmployeeResult} />
                    <Route path='/domesticpartsgrossperpartsemployee' component={DomesticPartsGrossPerPartsEmployee} />
                    <Route path='/domesticpartsgrossperpartsemployeeresult?total=:total' component={DomesticPartsGrossPerPartsEmployeeResult} />
                    <Route path='/importnonluxurypartsgrossperpartsemployee' component={ImportNonLuxuryPartsGrossPerPartsEmployee} />
                    <Route path='/importnonluxurypartsgrossperpartsemployeeresult?total=:total' component={ImportNonLuxuryPartsGrossPerPartsEmployeeResult} />
                    <Route path='/luxurypartsgrossperpartsemployee' component={LuxuryPartsGrossPerPartsEmployee} />
                    <Route path='/luxurypartsgrossperpartsemployeeresult?total=:total' component={LuxuryPartsGrossPerPartsEmployeeResult} />
                    <Route path='/partstolaborratio' component={PartsToLaborRatio} />
                    <Route path='/partstolaborratioresult?total=:total' component={PartsToLaborRatioResult} />
                    <Route path='/partsturnover' component={PartsTurnover} />
                    <Route path='/partsturnoverresult?total=:total' component={PartsTurnoverResult} />
                    <Route path='/inventoryprofile' component={InventoryProfile} />
                    <Route path='/partsmonthssupply' component={PartsMonthsSupply} />
                    <Route path='/partsmonthssupplyresult?total=:total' component={PartsMonthsSupplyResult} />
                    <Route path='/fillratefirsttime' component={FillRateFirstTime} />
                    <Route path='/fillratesameday' component={FillRateSameDay} />
                    <Route path='/partsactivenormal' component={PartsActiveNormal} />

                    {/*----PARTS - Gross Profit----*/}
                    <Route path='/totalpartsdepartmentgrosspercentsales' component={TotalPartsDepartmentGrossPercentSales} />
                    <Route path='/totalpartsdepartmentgrosspercentsalesresult?total=:total' component={TotalPartsDepartmentGrossPercentSalesResult} />
                    <Route path='/warrantyreceivables' component={WarrantyReceivables} />
                    <Route path='/warrantyreceivablesresult?total=:total' component={WarrantyReceivablesResult} />

                    {/*----PARTS - Body Shop Technician Efficiency----*/}
                    <Route path='/technicianefficiency' component={TechnicianEfficiency} />
                    <Route path='/technicianefficiencyresult?total=:total' component={TechnicianEfficiencyResults} />

                    {/*----PARTS - Gross Profit----*/}
                    <Route path='/bodyshopgrosssales' component={BodyShopGrossSales} />
                    <Route path='/bodyshopgrosssalesresult?total=:total' component={BodyShopGrossSalesResults} />
                    <Route path='/bodyshoplaborgrosssales' component={BodyShopLaborGrossSales} />
                    <Route path='/bodyshoplaborgrosssalesresult?total=:total' component={BodyShopLaborGrossSalesResults} />
                    <Route path='/partsbodyshopgrosssales' component={PartsBodyShopGrossSales} />
                    <Route path='/partsbodyshopgrosssalesresult?total=:total' component={PartsBodyShopGrossSalesResults} />
                    <Route path='/bodyshoppaintmaterialssales' component={BodyShopPaintMaterialsSales} />
                    <Route path='/bodyshoppaintmaterialssalesresult?total=:total' component={BodyShopPaintMaterialsSalesResults} />

                    </Switch>
                </Router>
            </div>
            <Footer />
        </div>
    );
}

export default withRouter(App);
