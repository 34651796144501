import React from 'react';
import { Link } from 'react-router-dom';
import BackButton from './_Shared/BackButton';
import { Collapse, Accordion, Card, Button } from 'react-bootstrap'

function Calculations() {

    return (

        <div className="container">
            <main role="main">
                <div className="jumbotron ">
                    <div className="col-sm-10 mx-auto">
                       <h2 className="text-center">
                            <BackButton />KPIs with Guides and Comparisons</h2>

                        <Accordion>
                        {/*----DEALERSHIP VITALS----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item" eventKey="0">
                                    <h5>Dealership Vitals &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div className="list-group">
                                            <Link to="/assetutilization"><button type="button" className="list-group-item list-group-item-action calculation-list calculation-list">Asset Utilization</button></Link>
                                            <Link to="/cashdayssupply"><button type="button" className="list-group-item list-group-item-action calculation-list">Cash Days Supply</button></Link>
                                            <Link to="/cashinbank"><button type="button" className="list-group-item list-group-item-action calculation-list">Cash in Bank</button></Link>
                                            <Link to="/currentratio"><button type="button" className="list-group-item list-group-item-action calculation-list">Current Ratio (Current Assets to Current Liabilities)</button></Link>
                                            <Link to="/debttoequity"><button type="button" className="list-group-item list-group-item-action calculation-list">Debt to Equity</button></Link>
                                            <Link to="/frozencapital"><button type="button" className="list-group-item list-group-item-action calculation-list">Frozen Capital</button></Link>
                                            <Link to="/inventorytrustposition"><button type="button" className="list-group-item list-group-item-action calculation-list">Inventory Trust Position</button></Link>
                                            <Link to="/lifo"><button type="button" className="list-group-item list-group-item-action calculation-list">LIFO (Last In, First Out)</button></Link>
                                            <Link to="/netprofitreturnonsales"><button type="button" className="list-group-item list-group-item-action calculation-list">Net Profit Return on Sales</button></Link>
                                            <Link to="/netprofitreturnonassets"><button type="button" className="list-group-item list-group-item-action calculation-list">Net Profit Return On Assets</button></Link>
                                            <Link to="/networth"><button type="button" className="list-group-item list-group-item-action calculation-list">Net Worth</button></Link>
                                            <Link to="/newvehicledepartmentbreakevenpoint"><button type="button" className="list-group-item list-group-item-action calculation-list">New Vehicle Department Breakeven Point</button></Link>
                                            <Link to="/partsobsolescence"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Obsolescence</button></Link>
                                            <Link to="/returnonequity"><button type="button" className="list-group-item list-group-item-action calculation-list">Return On Equity</button></Link>
                                            <Link to="/servicedepartmentproficiency"><button type="button" className="list-group-item list-group-item-action calculation-list">Service Department Proficiency</button></Link>
                                            <Link to="/servicesalespotentialandretention"><button type="button" className="list-group-item list-group-item-action calculation-list">Service Sales Potential and Retention</button></Link>
                                            <Link to="/totalabsorption"><button type="button" className="list-group-item list-group-item-action calculation-list">Total Absorption</button></Link>
                                            <Link to="/fixedabsorption"><button type="button" className="list-group-item list-group-item-action calculation-list">Fixed Absorption</button></Link>
                                            <Link to="/unitsinoperation"><button type="button" className="list-group-item list-group-item-action calculation-list">Units In Operation</button></Link>
                                            <Link to="/usedvehiclesdaysdupplyindollars"><button type="button" className="list-group-item list-group-item-action calculation-list">Used Vehicles Days Supply in Dollars</button></Link>
                                            <Link to="/usedvehiclesdaysdupplyinunits"><button type="button" className="list-group-item list-group-item-action calculation-list">Used Vehicles Days Supply in Units</button></Link>
                                            <Link to="/variableexpense"><button type="button" className="list-group-item list-group-item-action calculation-list">Variable Expense</button></Link>
                                            <Link to="/workingcapital"><button type="button" className="list-group-item list-group-item-action calculation-list">Working Capital</button></Link>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        {/*----NEW AND USED VEHICLE DEPARTMENT----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item" eventKey="1">
                                    <h5>New and Used Vehicle Department &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <div className="list-group">
                                            <Link to="/retailunitssoldpersalespeople"><button type="button" className="list-group-item list-group-item-action calculation-list">Retail Unit Sold per Salespeople</button></Link>
                                            <Link to="/usedtonewretailratio"><button type="button" className="list-group-item list-group-item-action calculation-list">Used to New Retail Ratio</button></Link>
                                            <Link to="/newinventorydayssupply"><button type="button" className="list-group-item list-group-item-action calculation-list">New Inventory Days Supply</button></Link>
                                            <Link to="/usedinventorydayssupply"><button type="button" className="list-group-item list-group-item-action calculation-list">Used Inventory Days Supply</button></Link>
                                            <Link to="/usedunitsover60days"><button type="button" className="list-group-item list-group-item-action calculation-list">Used Units Over 60 Days</button></Link>
                                            <Link to="/reconditioningturnaroundtime"><button type="button" className="list-group-item list-group-item-action calculation-list">Reconditioning Turnaround Time</button></Link>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        {/*----SERVICE----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item" eventKey="2">
                                    <h5>Service &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <div className="list-group">
                                            <Link to="/techsupport"><button type="button" className="list-group-item list-group-item-action calculation-list">Tech-to-Support Personnel Ratio</button></Link>
                                            <Link to="/techproficiency"><button type="button" className="list-group-item list-group-item-action calculation-list">Tech Proficiency</button></Link>
                                            <Link to="/techproductivity"><button type="button" className="list-group-item list-group-item-action calculation-list">Tech Productivity</button></Link>
                                            <Link to="/servicelabor"><button type="button" className="list-group-item list-group-item-action calculation-list">Service Labor Hours per RO</button></Link>
                                            <Link to="/stallutilization"><button type="button" className="list-group-item list-group-item-action calculation-list">Stall Utilization</button></Link>
                                            <Link to="/customerlabor"><button type="button" className="list-group-item list-group-item-action calculation-list">Customer Labor Gross Retention</button></Link>
                                            <Link to="/warrantylabor"><button type="button" className="list-group-item list-group-item-action calculation-list">Warranty Labor Gross Retention</button></Link>
                                            <Link to="/internallabor"><button type="button" className="list-group-item list-group-item-action calculation-list">Internal Labor Gross Retention</button></Link>
                                            <Link to="/grosssales"><button type="button" className="list-group-item list-group-item-action calculation-list">Total Service Department Gross % Sales</button></Link>
                                            <Link to="/receivables30"><button type="button" className="list-group-item list-group-item-action calculation-list">Service & Parts Receivables 0-30 Days</button></Link>
                                            <Link to="/receivables60"><button type="button" className="list-group-item list-group-item-action calculation-list">Service & Parts Receivables 31-60 Days</button></Link>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        {/*----PARTS----*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="list-group-item" eventKey="3">
                                    <h5>Parts &raquo;</h5>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <div className="list-group">
                                            <Link to="/domesticpartssalesperpartsemployee"><button type="button" className="list-group-item list-group-item-action calculation-list">Domestic Parts Sales Per Parts Employee</button></Link>
                                            <Link to="/importnonluxurypartssalesperpartsemployee"><button type="button" className="list-group-item list-group-item-action calculation-list">Import Non-Luxury Parts Sales Per Parts Employee</button></Link>
                                            <Link to="/luxurypartssalesperpartsemployee"><button type="button" className="list-group-item list-group-item-action calculation-list">Luxury Parts Sales Per Parts Employee</button></Link>
                                            <Link to="/domesticpartsgrossperpartsemployee"><button type="button" className="list-group-item list-group-item-action calculation-list">Domestic Parts Gross Per Parts Employee</button></Link>
                                            <Link to="/importnonluxurypartsgrossperpartsemployee"><button type="button" className="list-group-item list-group-item-action calculation-list">Import Non-Luxury Parts Gross Per Parts Employee</button></Link>
                                            <Link to="/luxurypartsgrossperpartsemployee"><button type="button" className="list-group-item list-group-item-action calculation-list">Luxury Parts Gross per Parts Employee</button></Link>
                                            <Link to="/partstolaborratio"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts To Labor Ratio</button></Link>
                                            <Link to="/partsturnover"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Turnover</button></Link>
                                            <Link to="/inventoryprofile"><button type="button" className="list-group-item list-group-item-action calculation-list">Inventory Profile</button></Link>
                                            <Link to="/partsmonthssupply"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Months Supply</button></Link>
                                            <Link to="/fillratefirsttime"><button type="button" className="list-group-item list-group-item-action calculation-list">Fill Rate First TIme</button></Link>
                                            <Link to="/fillratesameday"><button type="button" className="list-group-item list-group-item-action calculation-list">Fill Rate Same Day Pick-up</button></Link>
                                            <Link to="/partsactivenormal"><button type="button" className="list-group-item list-group-item-action calculation-list">Active/Normal Status</button></Link>
                                            <Link to="/totalpartsdepartmentgrosspercentsales"><button type="button" className="list-group-item list-group-item-action calculation-list">Total Parts Department Gross Percent Sales</button></Link>
                                            <Link to="/warrantyreceivables"><button type="button" className="list-group-item list-group-item-action calculation-list">Warranty Receivables</button></Link>
                                            <p className="bodyshop">Body Shop</p>
                                            <Link to="/technicianefficiency"><button type="button" className="list-group-item list-group-item-action calculation-list">Body Shop Technician Efficiency</button></Link>                                          
                                            <Link to="/bodyshopgrosssales"><button type="button" className="list-group-item list-group-item-action calculation-list">Total Body Shop Gross % Sales</button></Link>
                                            <Link to="/bodyshoplaborgrosssales"><button type="button" className="list-group-item list-group-item-action calculation-list">Body Shop Labor Gross % Sales</button></Link>
                                            <Link to="/partsbodyshopgrosssales"><button type="button" className="list-group-item list-group-item-action calculation-list">Parts Body Shop RO Gross % Sales</button></Link>
                                            <Link to="/bodyshoppaintmaterialssales"><button type="button" className="list-group-item list-group-item-action calculation-list">Body Shop Paint & Materials Gross % Sales</button></Link>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        </Accordion>
                    </div>
                </div>
            </main>
        </div>
    );
}


export default Calculations;