import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function PartsObsolescence() {
    const [parts, setParts] = useState(0);
    const [inventory, setInventory] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
            setTotal(
                ((parseInt(parts) / parseInt(inventory)) * 100)
            );
        }, [parts, inventory])

    function clickResult() {
        history.push(
            {
                pathname: `/partsobsolescenceresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        parts: numberValidation,
        inventory: numberValidation
    });

    const def = <span>Parts inventory aged over 12 months with no demand</span>;

    return (
        <Formik
            initialValues={{ parts: "", inventory: ""}}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Parts Obsolescence</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="parts">
                                            <Form.Label>Parts Obsolescence</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="parts" isInvalid={!!errors.parts}
                                                    value={values.parts} onChange={e => { handleChange(e); setParts(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.parts}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="inventory">
                                            <Form.Label>Total P&A Inventory Value</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="inventory" isInvalid={!!errors.inventory}
                                                    value={values.inventory} onChange={e => { handleChange(e); setInventory(e.target.value) }}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.inventory}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function PartsObsolescenceResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Parts Obsolescence";
    const total = urlParams.get('total') + "%";
    const ratio = "5% or less";

    const cond = parseFloat(total) <= 5.00;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    PartsObsolescence,
    PartsObsolescenceResult
}