import React from 'react';
import BackButton from '../_Shared/BackButton';




function ServiceSalesPotentialAndRetention() {
    return (
        <div className="container">
            <div className="jumbotron">
                <div className="col-sm-10 mx-auto">
                    <BackButton /><h2 className="text-center">Service Sales Potential and Retention</h2>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card text-white rounded formula-definition">
                                <div className="card-header text-center" id="formula">Formula Definition: 5-, 7- or 10-year* owner base (new vehicle units in operation 
                                for a given brand and market area of responsibility) x 7-8 hours per year x O.E.L.R
                                <br />
                                    NADA Guide: 50% minimum (national average is 35%) *(manufacturer specifies number of years)
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}




export {
    ServiceSalesPotentialAndRetention
}