import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function RetailUnitSoldPerSalespeople() {
    const [newRetail, setNewRetail] = useState(0);
    const [usedRetail, setUsedRetail] = useState(0);
    const [months, setMonths] = useState(0);
    const [newSalespeople, setNewSalesPeople] = useState(0);
    const [usedSalespeople, setUsedSalesPeople] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();


    useEffect(() => {
        if (parseInt(months) == ' ') {
            setTotal(
                (parseInt(newRetail) + parseInt(usedRetail)) / (parseInt(newSalespeople) + parseInt(usedSalespeople))
            );
        }
        else {
            setTotal(
                ((parseInt(newRetail) + parseInt(usedRetail)) / parseInt(months)) / (parseInt(newSalespeople) + parseInt(usedSalespeople))
            );
        }
        
    }, [newRetail, usedRetail, months, newSalespeople, usedSalespeople])

    function clickResult() {
        history.push(
            {
                pathname: `/retailunitssoldpersalespeopleresult?total=${parseFloat(total).toFixed(1)}`
            })
    }

    const validationSchema = Yup.object({
        newRetail: numberValidation,
        usedRetail: numberValidation,
        months: monthValidation,
        newSalespeople: numberValidation,
        usedSalespeople: numberValidation
    });

    const def = <span>((New + Used Retail Units Sold) &#247; #of Months) &#247; # of Salespeople</span>;

    return (
        <Formik
            initialValues={{ newRetail: "", usedRetail: "", months: "", newSalespeople: "", usedSalespeople: ""}}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Retail Unit Sold per Salespeople</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newRetail">
                                            <Form.Label>New Retail Units Sold </Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="newRetail" value={values.newRetail} isInvalid={!!errors.newRetail}
                                                onChange={e => { handleChange(e); setNewRetail(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.newRetail}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedRetail">
                                            <Form.Label>Used Retail Units Sold </Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="usedRetail" value={values.usedRetail} isInvalid={!!errors.usedRetail}
                                                onChange={e => { handleChange(e); setUsedRetail(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.usedRetail}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label># of Months of Units Sold above</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" value={values.months} isInvalid={!!errors.months}
                                                onChange={e => { handleChange(e); setMonths(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="newSalespeople">
                                            <Form.Label>New Salespeople</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="newSalespeople" value={values.newSalespeople} isInvalid={!!errors.newSalespeople}
                                                onChange={e => { handleChange(e); setNewSalesPeople(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.newSalespeople}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedSalespeople">
                                            <Form.Label>Used Salespeople</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="usedSalespeople" value={values.usedSalespeople} isInvalid={!!errors.usedSalespeople}
                                                onChange={e => { handleChange(e); setUsedSalesPeople(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.usedSalespeople}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function RetailUnitSoldPerSalespeopleResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Retail Unit Sold per Salespeople";
    const total = urlParams.get('total') + " units per month";
    const ratio = "12-15 units per month";

    //const cond = parseFloat(total) >= 11 && parseFloat(total) <= 13;
    const cond = parseFloat(total) >= 12.00;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    RetailUnitSoldPerSalespeople,
    RetailUnitSoldPerSalespeopleResult
}