import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../../App.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
            <Navbar className="navbar navbar-dark bg-dark-top">
                    <Link to='/' className='navbar-brand'>
                        <img src="/images/NADAMark_white.png" alt="NADA Logo" height="46px" />
                    </Link>

            <NavbarToggler onClick={this.toggleNavbar} className="navbar-toggler collapsed"><span className="navbar-toggler-icon" /></NavbarToggler>
            <Collapse className="navbar-collapse collapsed" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav mr-auto">
                <NavItem>
                  <NavLink tag={Link} className="nav-link float-right" to="/">Home</NavLink>
                </NavItem>
              </ul>
            </Collapse>
        </Navbar>
    );
  }
}
