import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function LuxuryPartsSalesPerPartsEmployee() {
    const [totalSales, setTotalGross] = useState(0);
    const [months, setMonths] = useState(0);
    const [totalEmployees, setTotalEmployees] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();


    useEffect(() => {
        setTotal(((parseInt(totalSales) / parseInt(totalEmployees)) / parseInt(months)));
    }, [totalSales, months, totalEmployees])

    function clickResult() {
        history.push(
            {
                pathname: `/luxurypartssalesperpartsemployeeresult?total=${parseFloat(total).toFixed(0)}`
            })
    }

    const validationSchema = Yup.object({
        totalSales: numberValidation,
        months: monthValidation,
        totalEmployees: numberValidation
    });

    const def = <span>(Total Parts Department Sales  &#247; # of Months) &#247; Total Parts Employees(Import Luxury)</span>;

    return (
        <Formik
            initialValues={{ totalSales: "", months: "", totalEmployees: "", }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Luxury Parts Sales Per Parts Employee</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="totalSales">
                                            <Form.Label>Total Parts Department Sales </Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="totalSales" value={values.totalSales} isInvalid={!!errors.totalSales}
                                                onChange={e => { handleChange(e); setTotalGross(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.totalSales}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label>Number of Months</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" value={values.months} isInvalid={!!errors.months}
                                                onChange={e => { handleChange(e); setMonths(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="totalEmployees">
                                            <Form.Label>Total Parts Employees (Import Luxury)</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="totalEmployees" value={values.totalEmployees} isInvalid={!!errors.totalEmployees}
                                                onChange={e => { handleChange(e); setTotalEmployees(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.totalEmployees}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function LuxuryPartsSalesPerPartsEmployeeResult() {
    const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));

    const urlParams = new URLSearchParams(window.location.search);
    const title = "Luxury Parts Sales Per Parts Employee";
    const totalInt = urlParams.get('total');
    const total = '$' + priceSplitter(totalInt);
    const ratio = "$70,000+";

    const cond = parseFloat(totalInt) >= 70000;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    LuxuryPartsSalesPerPartsEmployee,
    LuxuryPartsSalesPerPartsEmployeeResult
}