import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';


function NetProfitReturnOnAssets() {
    const [netProfit, setNetProfit] = useState(0);
    const [assets, setAssets] = useState(0);
    const [months, setMonths] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {

        if (parseInt(months) == ' ') {
            setTotal(
                ((parseInt(netProfit) / parseInt(assets)))
            );
        }
        else {
            setTotal(
                (((parseInt(netProfit) / parseInt(months)) * 12) / parseInt(assets)) * 100
            );
        }
    }, [netProfit, months, assets])

    function clickResult() {
        history.push(
            {
                pathname: `/netprofitreturnonassetsresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        netProfit: numberValidation,
        assets: numberValidation,
        months: monthValidation
    });

    const def = <span>Return % sufficient to justify operations. Annualized Profits ÷ Total Assets (+ LIFO if recorded on asset side of financial statement).</span>;

    return (
        <Formik
            initialValues={{ netProfit: "", assets: "", months: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Net Profit Return On Assets</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="assets">
                                            <Form.Label>Total Assets</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="assets" isInvalid={!!errors.assets}
                                                value={values.assets} onChange={e => { handleChange(e); setAssets(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.assets}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="netProfit">
                                            <Form.Label>Net Profit before Tax</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="netProfit" isInvalid={!!errors.netProfit}
                                                    value={values.netProfit} onChange={e => { handleChange(e); setNetProfit(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.netProfit}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label># of Months for Net Profit $ above</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="months" isInvalid={!!errors.months}
                                                value={values.months} onChange={e => { handleChange(e); setMonths(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function NetProfitReturnOnAssetsResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Net Profit Return On Assets";
    const total = urlParams.get('total') + "%";
    const ratio = "15 - 20%";

    

    const cond = parseFloat(total) >= 15 && parseFloat(total) <= 20;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    NetProfitReturnOnAssets,
    NetProfitReturnOnAssetsResult
}