import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function UsedInventoryDaysSupply() {
    const [usedInventory, setUsedInventory] = useState(0);
    const [usedRetail, setUsedRetail] = useState(0);
    const [usedWholesale, setWholesale] = useState(0);
    const [retailProfit, setRetailProfit] = useState(0);
    const [wholesaleProfit, setWholesaleProfit] = useState(0);
    const [months, setMonths] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();


    useEffect(() => {
        if (parseInt(months) == ' ') {
            setTotal
                ((parseInt(usedInventory)) / (((parseInt(usedRetail) + parseInt(usedWholesale)) - (parseInt(retailProfit) + parseInt(wholesaleProfit)))) * 30);
        }
        else {
            setTotal
                ((parseInt(usedInventory)) / (((parseInt(usedRetail) + parseInt(usedWholesale)) - (parseInt(retailProfit) + parseInt(wholesaleProfit))) / parseInt(months)) * 30);
        }
        

    }, [usedInventory, usedRetail, usedWholesale, retailProfit, wholesaleProfit, months])

    function clickResult() {
        history.push(
            {
                pathname: `/usedinventorydayssupplyresult?total=${parseFloat(total).toFixed(1)}`
            })
    }

    const validationSchema = Yup.object({
        usedInventory: numberValidation,
        usedRetail: numberValidation,
        usedWholesale: numberValidation,
        retailProfit: numberValidation,
        wholesaleProfit: numberValidation,
        months: monthValidation
    });

    const def = <span>Used Inventory $ ÷ ((((Used Vehicle Retail Sales + Used Wholesale Sales) - (Used Vehicle Retail Gross Profit + Used Wholesale Gross Profit)) ÷ # of Months) x 30)</span>;

    return (
        <Formik
            initialValues={{ usedInventory: "", usedRetail: "", usedWholesale: "", retailProfit: "", wholesaleProfit: "", months: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Used Inventory Days Supply</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedInventory">
                                            <Form.Label>Used Vehicle Inventory</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="usedInventory" value={values.usedInventory} isInvalid={!!errors.usedInventory}
                                                onChange={e => { handleChange(e); setUsedInventory(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.usedInventory}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedRetail">
                                            <Form.Label>Used Vehicle Retail Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="usedRetail" value={values.usedRetail} isInvalid={!!errors.usedRetail}
                                                onChange={e => { handleChange(e); setUsedRetail(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.usedRetail}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="usedWholesale">
                                            <Form.Label>Used Wholesale Sales</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="usedWholesale" value={values.usedWholesale} isInvalid={!!errors.usedWholesale}
                                                onChange={e => { handleChange(e); setWholesale(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.usedWholesale}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="retailProfit">
                                            <Form.Label>Used Vehicle Retail Gross Profit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="retailProfit" value={values.retailProfit} isInvalid={!!errors.retailProfit}
                                                onChange={e => { handleChange(e); setRetailProfit(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.retailProfit}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="wholesaleProfit">
                                            <Form.Label>Used Wholesale Gross Profit</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="wholesaleProfit" value={values.wholesaleProfit} isInvalid={!!errors.wholesaleProfit}
                                                onChange={e => { handleChange(e); setWholesaleProfit(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.wholesaleProfit}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="months">
                                            <Form.Label>Number of Months</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="months" value={values.months} isInvalid={!!errors.months}
                                                onChange={e => { handleChange(e); setMonths(e.target.value) }} />
                                                <Form.Control.Feedback type="invalid">{errors.months}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function UsedInventoryDaysSupplyResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Used Inventory Days Supply";
    const total = urlParams.get('total') + " Days";
    const ratio = <p>30 Days<br /><h2>(12 turns per year)</h2></p>;

    const cond = parseFloat(total) <= 30;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    UsedInventoryDaysSupply,
    UsedInventoryDaysSupplyResult
}