import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function TechnicianEfficiency() {
    const [flatRateHours, setFlatRateHours] = useState(0);
    const [hoursWorked, setHoursWorked] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal((parseInt(flatRateHours) / parseInt(hoursWorked)) *100);
    }, [flatRateHours, hoursWorked])

    function clickResult() {
        history.push(
            {
                pathname: `/technicianefficiencyresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        flatRateHours: numberValidation,
        hoursWorked: numberValidation
    });

    const def = <span>Flat Rate Hours &#247; Hours Worked</span>;

    return (
        <Formik
            initialValues={{ flatRateHours: "", hoursWorked: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Body Shop Technician Efficiency</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" mb="3" controlId="flatRateHours">
                                            <Form.Label>Body Shop Flat Rate Hours</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control size="lg" type="number" name="flatRateHours" value={values.flatRateHours} isInvalid={!!errors.flatRateHours}
                                                onChange={e => { handleChange(e); setFlatRateHours(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.flatRateHours}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="hoursWorked">
                                            <Form.Label>Body Shop Hours Worked</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend> <InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="hoursWorked" value={values.hoursWorked} isInvalid={!!errors.hoursWorked}
                                                onChange={e => { handleChange(e); setHoursWorked(e.target.value) }} />
                                            <Form.Control.Feedback type="invalid">{errors.hoursWorked}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn btn-primary btn-lg btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function TechnicianEfficiencyResults() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Body Shop Technician Efficiency";
    const total = urlParams.get('total') + '%';
    const ratio = <h5>Apprentice : 100% <br /> Journeyman : 150% <br /> Master: 200%</h5>;

    //const cond = parseFloat(total) >= 100 && parseFloat(total) <= 200;
    const cond = parseFloat(total) >= 100;



    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}

export {
    TechnicianEfficiency,
    TechnicianEfficiencyResults
}