import React, { useState, useEffect } from 'react';
import BackButton from '../_Shared/BackButton';
import { Form, Col, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { numberValidation, monthValidation } from '../_Shared/Validation';
import result from '../_Shared/Result';
import formulaDefinition from '../_Shared/FormulaDefinition';

function ServiceDepartmentProficiency() {
    const [hoursProduced, setHoursProduced] = useState(0);
    const [hoursAvailable, setHoursAvailable] = useState(0);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        setTotal((parseInt(hoursAvailable) / parseInt(hoursProduced) * 100));
    }, [hoursProduced, hoursAvailable])

    function clickResult() {
        history.push(
            {
                pathname: `/servicedepartmentproficiencyresult?total=${parseFloat(total).toFixed(2)}`
            })
    }

    const validationSchema = Yup.object({
        hoursProduced: numberValidation,
        hoursAvailable: numberValidation
    });

    const def = <span>Hours Produced ÷ Hours Available</span>;

    return (
        <Formik
            initialValues={{ hoursProduced: "", hoursAvailable: "" }}
            validationSchema={validationSchema}
            onSubmit={e => {
                clickResult();
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (

                <div className="container">
                    <main role="main">
                        <div className="jumbotron ">
                            <div className="col-sm-10 mx-auto">
                                <BackButton />
                                <h2 className="text-center">Service Department Proficiency</h2>

                                {formulaDefinition(def)}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Row>

                                        <Form.Group as={Col} md="12" mb="3" controlId="hoursProduced">
                                            <Form.Label>Total Available Hours for All Techs</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="hoursProduced" isInvalid={!!errors.hoursProduced}
                                                value={values.hoursProduced} onChange={e => { handleChange(e); setHoursProduced(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.hoursProduced}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" mb="3" controlId="hoursAvailable">
                                            <Form.Label>Total Hours Produced for All Tech</Form.Label>
                                            <InputGroup className="mb-2"><InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                                            <Form.Control size="lg" type="number" name="hoursAvailable" isInvalid={!!errors.hoursAvailable}
                                                value={values.hoursAvailable} onChange={e => { handleChange(e); setHoursAvailable(e.target.value) }}
                                            />
                                                <Form.Control.Feedback type="invalid">{errors.hoursAvailable}</Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                    </Form.Row>
                                    <p className="pb-5">
                                        <button className="btn-lg btn-primary btn-block" type="submit">Calculate &raquo;</button>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </Formik>
    );

}

function ServiceDepartmentProficiencyResult() {
    const urlParams = new URLSearchParams(window.location.search);
    const title = "Service Department Proficiency";
    const total = urlParams.get('total') + "%";
    const ratio = "120%-125% Minimum acceptable: 100%";

    //const cond = parseFloat(total) >= 100 && parseFloat(total) <= 125;
    const cond = parseFloat(total) >= 100 ;

    return (
        <div>
            {result(title, total, ratio, cond)}
        </div>
    );
}


export {
    ServiceDepartmentProficiency,
    ServiceDepartmentProficiencyResult
}